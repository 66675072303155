// クライアント（遷移元のプロダクト）の設定
// サーバ側で定義すべき値もありますが、現在はハードコードしています

export type ClientSetting = {
  id: ClientIds
  productName: string
}

export type ClientIds = 'default' | 'momo'

export const clientSettings: Record<ClientIds, ClientSetting> = {
  default: {
    id: 'default',
    productName: '__NAME__',
  },
  momo: {
    id: 'momo',
    productName: 'MOMO',
  },
}

export const COOKIE_LOGIN_CLIENT_ID_MOMO = 'momo'

export const getClientSettingByCookieLoginClientId = (clientId: string) => {
  if (clientId === COOKIE_LOGIN_CLIENT_ID_MOMO) {
    return clientSettings.momo
  }

  return null
}
