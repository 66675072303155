import React, { useCallback, useMemo } from 'react'
import { useOIDCLoginParams } from 'src/oidc/useParams'
import { useCookieLoginParams } from 'src/cookieLogin/useParams'
import { ConnectParams, toSearchParams } from './params'
import { ConnectParamsContext } from './paramsContext'

// 各種連携パラメータ（OIDC連携、セッション連携）を取得し、Contextに注入します
// deprecated: 未使用となる予定
export const RouterConnectParamsProvider = ({ children }: { children: React.ReactNode }) => {
  const oidcLoginParams = useOIDCLoginParams()
  const cookieLoginParams = useCookieLoginParams()

  const paramsReady = oidcLoginParams.paramsReady && cookieLoginParams.paramsReady
  const params = useMemo(
    () => ({ ...oidcLoginParams.params, ...cookieLoginParams.params }),
    [oidcLoginParams.params, cookieLoginParams.params],
  )

  const appendParamsToLink = useCallback(
    (link: { path: string; params?: URLSearchParams }) => {
      const s = toSearchParams(params, link.params)
      return `${link.path}?${s.toString()}`
    },
    [params],
  )

  return (
    <ConnectParamsContext.Provider
      value={{
        paramsReady,
        params,
        appendParamsToLink,
      }}
    >
      {children}
    </ConnectParamsContext.Provider>
  )
}

export const ConnectParamsProvider = ({
  connectParams,
  children,
}: {
  connectParams: ConnectParams
  children: React.ReactNode
}) => {
  const paramsReady = true
  const params = connectParams

  const appendParamsToLink = useCallback(
    (link: { path: string; params?: URLSearchParams }) => {
      const s = toSearchParams(params, link.params)
      return `${link.path}?${s.toString()}`
    },
    [params],
  )

  return (
    <ConnectParamsContext.Provider
      value={{
        paramsReady,
        params,
        appendParamsToLink,
      }}
    >
      {children}
    </ConnectParamsContext.Provider>
  )
}
