import type { ParsedUrlQuery } from 'querystring'

export type OIDCLoginParams = {
  oidc: {
    challenge: string
  } | null
}

export const getParams = (query: ParsedUrlQuery): OIDCLoginParams => {
  const { login_challenge: challenge } = query as {
    login_challenge: string | undefined
  }

  return {
    oidc: challenge
      ? {
          challenge,
        }
      : null,
  }
}

export const toSearchParams = (params: OIDCLoginParams, base?: URLSearchParams) => {
  const s = new URLSearchParams(base)
  if (params.oidc) {
    s.append('login_challenge', params.oidc.challenge)
  }

  return s
}
