import type { ParsedUrlQuery } from 'querystring'

export type CookieLoginParams = {
  cookieLogin: {
    clientId: string
    redirectUri: string
  } | null
}

export const getParams = (query: ParsedUrlQuery): CookieLoginParams => {
  const { client_id: clientId, redirect_uri: redirectUri } = query as {
    client_id: string | undefined
    redirect_uri: string | undefined
  }

  return {
    cookieLogin:
      clientId && redirectUri
        ? {
            clientId,
            redirectUri,
          }
        : null,
  }
}

export const toSearchParams = (params: CookieLoginParams, base?: URLSearchParams) => {
  const s = new URLSearchParams(base)
  if (params.cookieLogin) {
    s.append('client_id', params.cookieLogin.clientId)
    s.append('redirect_uri', params.cookieLogin.redirectUri)
  }

  return s
}

// FIXME: 暫定対応
// 404ページで正しい戻り先を決定できないため、固定のパラメータに逃がす
export const momoDefaultParams = () => {
  return toSearchParams({
    cookieLogin: {
      clientId: 'momo',
      redirectUri: process.env.COOKIE_LOGIN_MOMO_DEFAULT_CALLBACK_URL!,
    },
  })
}
